@import './LexiconEditorStyles';

@mixin lexicon-font {
  font-family: "Helvetica Neue", Helvetica, sans-serif;

  body.windows & {
    font-family: 'Lucida Sans Unicode', 'Lucida', Verdana, Arial, sans-serif;
  }
}

@mixin button {
  @include lexicon-font;
  font-style: normal;
  text-decoration: none;
  color: white;
  background-color: $gray_75;
  padding: 2px 8px;
  border-radius: 3px;
  border: none;
  cursor: pointer;


  &:hover:not(:disabled) {
    color: white;
    background-color: $green;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.EditWrapper {
  .buttons {
    display: flex;
    justify-content: space-between;

    button.edit-wrapper-button, div.edit-wrapper-button, {
      @include button;
    }
  }

  .wrapped-lexicon-editor {
    position: fixed;
    border-radius: 4px;
    border: thin solid $gray_25;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    background-color: $lexicon_editor_background;
    overflow: hidden;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;

    visibility: hidden;
    opacity: 0;
    transition: 0.1s visibility, 0.1s opacity, 0.1s position;

    &.is-visible {
      visibility: visible;
      opacity: 1;
    }

    &.docked-left {
      width: 30vw;
      height: calc(100vh - 16px);
      top: 8px;
      left: 8px;
    }

    &.docked-right {
      width: 30vw;
      height: calc(100vh - 16px);
      top: 8px;
      right: 8px;
    }

    &.docked-bottom {
      width: calc(100vw - 32px);
      height: 30vh;
      left: 8px;
      bottom: 8px;
    }

    hgroup {
      flex: 0 0 auto; // make small at top
      display: flex; // layout title and position buttons within
      flex-direction: row;

      .wrapper-heading {
        @include lexicon-font;
        display: inline;
        margin: 5px 0;
        padding-left: 10px;
        font-size: 16px;
        flex: auto;
      }

      .position {
        flex: 0 0 auto;

        label {
          color: $gray_50;
          font-size: 20px;
          cursor: pointer;
          padding: 2px 6px;

          &.selected {
            color: $gray_25;
          }

          &:hover {
            background-color: $clickable;
            border-radius: 20px;
          }

          input {
            display: none;
          }
        }
      }

      .close-btn {
        font-size: 20px;
        cursor: pointer;
        padding: 0 8px;

        &:hover {
          background-color: $clickable;
          border-radius: 20px;
        }
      }
    }

    .LexiconEditor {
      flex: 1;
    }

    .save-box {
      flex: 0 0 auto;
      text-align: right;

      button {
        @include button;
        margin: 8px;
        width: 200px;
        background-color: $clickable;

        &:disabled {
          background-color: $gray_75;
        }
      }
    }

    .error-message {
      padding: 4px;
      color: $dark_orange;
      font-size: 14px;
    }
  }
}
